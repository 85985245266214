import {
  LayerProps,
  LeafletContextInterface,
  createElementObject,
  createTileLayerComponent,
} from "@react-leaflet/core";
import {
  GridLayer,
  Coords,
  GridLayerOptions,
  LatLng,
  TileLayerOptions,
} from "leaflet";

import { QueueManager } from "../library/queue";
import { DrawSeed } from "../library/draw";
import {
  WorldCoords,
  tileCoordsToLatLng,
  tileCoordsToWorldCoords,
  zoomToDrawScale,
} from "../util/leafletHelpers";

interface BiomesLayerProps extends TileLayerOptions, LayerProps {
  drawer: DrawSeed;
  queue: QueueManager;
  mcVersion: number;
  seed: string | number;
  dimension: number;
  yHeight: number;
}

class BiomesLayerElement extends GridLayer {
  drawer: DrawSeed;
  queue: QueueManager;
  mcVersion: number;
  seed: string | number;
  dimension: number;
  yHeight: number;

  constructor(props: BiomesLayerProps, options?: GridLayerOptions) {
    super(options);
    ({
      drawer: this.drawer,
      queue: this.queue,
      mcVersion: this.mcVersion,
      seed: this.seed,
      dimension: this.dimension,
      yHeight: this.yHeight,
    } = props);
  }

  drawTileDebug(
    ctx: CanvasRenderingContext2D | null,
    coords: Coords,
    worldCoords: WorldCoords,
    latLng: LatLng
  ) {
    if (process.env.NODE_ENV === "production") return;

    ctx?.beginPath();
    ctx?.moveTo(0, 0);
    ctx?.lineTo(10, 0);
    ctx?.moveTo(0, 0);
    ctx?.lineTo(0, 10);
    ctx?.stroke();

    ctx?.strokeText(`T: ${coords.x}, ${coords.y}, ${coords.z}`, 10, 10);
    ctx?.strokeText(
      `W: ${worldCoords.x}, ${worldCoords.y}, ${worldCoords.z}`,
      10,
      30
    );
    ctx?.strokeText(`L: ${latLng.lat}, ${latLng.lng}`, 10, 50);
  }

  createTile(coords: Coords) {
    const tile = document.createElement("canvas");
    const tileSize = this.getTileSize();
    const width: number = tileSize.x;
    const height: number = tileSize.y;
    tile.setAttribute("width", width.toString());
    tile.setAttribute("height", height.toString());

    if (!this.seed) return tile;

    const ctx = tile.getContext("2d");
    const drawScale = zoomToDrawScale(coords.z);

    const worldCoords = tileCoordsToWorldCoords(coords, coords.z);
    const latLng = tileCoordsToLatLng(coords, coords.z);
    this.drawTileDebug(ctx, coords, worldCoords, latLng);

    this.queue.draw(
      this.mcVersion,
      this.seed,
      worldCoords.x / drawScale,
      worldCoords.z / drawScale,
      width,
      height,
      this.dimension,
      this.yHeight,
      (colors) => {
        this.drawer._drawLoop(
          ctx,
          colors,
          worldCoords.x / drawScale,
          worldCoords.z / drawScale,
          0,
          0,
          width,
          height
        );
        //if (this.toDraw === 1) {
        //  this.drawStructures();
        //}
        //this.toDraw--;

        this.drawTileDebug(ctx, coords, worldCoords, latLng);
      }
    );

    return tile;
  }
}

const createBiomesLayer = (
  props: BiomesLayerProps,
  context: LeafletContextInterface
) => {
  const element = new BiomesLayerElement(props);
  return createElementObject(element, context);
};

const updateBiomesLayer = (
  instance: BiomesLayerElement,
  props: BiomesLayerProps,
  prevProps: BiomesLayerProps
) => {
  if (
    props.mcVersion !== prevProps.mcVersion ||
    props.seed !== prevProps.seed ||
    props.dimension !== prevProps.dimension ||
    props.yHeight !== prevProps.yHeight
  ) {
    instance.mcVersion = props.mcVersion;
    instance.seed = props.seed;
    instance.dimension = props.dimension;
    instance.yHeight = props.yHeight;
    instance.redraw();
  }
};

export const BiomesLayer = createTileLayerComponent(
  createBiomesLayer,
  updateBiomesLayer
);

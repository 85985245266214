export const debounce = (func: Function, wait = 25) => {
  let timeout: ReturnType<typeof setTimeout>;
  return (...args: any[]) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(this, args);
    }, wait);
  };
};

export const copyToClipboard = (textToCopy: string) => {
  // navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    // navigator clipboard api method'
    return navigator.clipboard.writeText(textToCopy);
  } else {
    // text area method
    let textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    // make the textarea out of viewport
    textArea.style.position = "fixed";
    textArea.style.left = "-999999px";
    textArea.style.top = "-999999px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise<void>((res, rej) => {
      // here the magic happens
      document.execCommand("copy") ? res() : rej();
      textArea.remove();
    });
  }
};

interface SetUrlOptions {
  serverId?: string | null;
  seed?: string | number | null;
  mcVersion?: number | null;
}

export const setUrl = ({ serverId, seed, mcVersion }: SetUrlOptions) => {
  if (window.history.pushState) {
    let newUrl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname;
    newUrl +=
      (serverId ? `?serverId=${serverId}&` : `?`) +
      `seed=${seed}&version=${mcVersion}`;
    window.history.pushState({ path: newUrl }, "", newUrl);
  }
};

export function hashString(str: string, seed = 0) {
  let h1 = 0xdeadbeef ^ seed,
    h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 =
    Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^
    Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 =
    Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^
    Math.imul(h1 ^ (h1 >>> 13), 3266489909);
  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
}

export const seedFromString = (s: string) => {
  if (isNumeric(s)) {
    return Number(s);
  }

  let h = 0;
  for (let i = 0; i < s.length; i++) {
    h = (Math.imul(31, h) + s.charCodeAt(i)) | 0;
  }
  return h;
};

export function getRandomSeed() {
  return "" + Math.floor(-4_294_967_296 + Math.random() * 8_589_934_593);
}

export function isNumeric(value: string | number) {
  if (typeof value === "number") return true;
  return !isNaN(Number(value));
}

import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from "react";

import { QueueManager } from "../library/queue";
import { VERSIONS } from "../util/constants";
import { getRandomSeed, isNumeric, seedFromString } from "../util/functions";
import db from "../db.json";

interface MapContextType {
  serverId: string | null;
  setServerId: React.Dispatch<React.SetStateAction<string | null>>;
  mcVersion: number;
  setMcVersion: React.Dispatch<React.SetStateAction<number>>;
  seed: string | number;
  setSeed: React.Dispatch<React.SetStateAction<string | number>>;
  dimension: number;
  setDimension: React.Dispatch<React.SetStateAction<number>>;
  yHeight: number;
  setYHeight: React.Dispatch<React.SetStateAction<number>>;
  queueManager: QueueManager;
  x: number | null;
  setX: React.Dispatch<React.SetStateAction<number | null>>;
  z: number | null;
  setZ: React.Dispatch<React.SetStateAction<number | null>>;
  biome: string | null;
  setBiome: React.Dispatch<React.SetStateAction<string | null>>;
  biomesToFind: number[] | null;
  setBiomesToFind: React.Dispatch<React.SetStateAction<number[] | null>>;
  range: number | null;
  setRange: React.Dispatch<React.SetStateAction<number | null>>;
  structureToFind: number | null;
  setStructureToFind: React.Dispatch<React.SetStateAction<number | null>>;
  structuresToShow: number[];
  setStructuresToShow: React.Dispatch<React.SetStateAction<number[]>>;
  isDrawingSeed: boolean;
  setIsDrawingSeed: React.Dispatch<React.SetStateAction<boolean>>;
  isFindingSeed: boolean;
  setIsFindingSeed: React.Dispatch<React.SetStateAction<boolean>>;
  seedFindingSpeed: boolean | number;
  setSeedFindingSpeed: React.Dispatch<React.SetStateAction<boolean | number>>;
  lastFoundSeed: number;
  setLastFoundSeed: React.Dispatch<React.SetStateAction<number>>;
  menuToggled: boolean;
  setMenuToggled: React.Dispatch<React.SetStateAction<boolean>>;
  showStrongholds: boolean;
  setShowStrongholds: React.Dispatch<React.SetStateAction<boolean>>;
  showStructureCoords: boolean;
  setShowStructureCoords: React.Dispatch<React.SetStateAction<boolean>>;
  showClaims: boolean;
  setShowClaims: React.Dispatch<React.SetStateAction<boolean>>;
  showOverworldClaimsInNether: boolean;
  setShowOverworldClaimsInNether: React.Dispatch<React.SetStateAction<boolean>>;
  showLegend: boolean;
  setShowLegend: React.Dispatch<React.SetStateAction<boolean>>;

  findSeed: () => void;
  restartAll: () => void;
}

const MapContext = createContext<MapContextType | null>(null);

export function useMapContext() {
  const context = useContext(MapContext);
  if (!context) {
    throw new Error("useMapContext must be used within a MapContextProvider");
  }
  return context;
}

export function MapContextProvider({ children }: PropsWithChildren) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const urlServerId = params?.serverId;
  const urlSeed = params?.seed;
  const urlVersion = params?.version;

  const [serverId, setServerId] = useState<string | null>(urlServerId ?? null);
  const server = db.servers?.find((server) => server.id === serverId) ?? null;

  const [mcVersion, setMcVersion] = useState(
    server && VERSIONS[server.version]
      ? VERSIONS[server.version]
      : isNumeric(urlVersion)
      ? Number.parseInt(urlVersion)
      : VERSIONS["1.20"]
  );
  const [seed, setSeed] = useState(
    server
      ? Number(server.seed)
      : Number.isInteger(Number.parseInt(urlSeed))
      ? Number(urlSeed) + ""
      : urlSeed
      ? seedFromString(urlSeed)
      : getRandomSeed()
  );

  const [dimension, setDimension] = useState(0); // Overworld
  const [yHeight, setYHeight] = useState(256); // Mountain tops
  const [queueManager] = useState(() => new QueueManager("/workers/worker.js"));

  const [x, setX] = useState<number | null>(null);
  const [z, setZ] = useState<number | null>(null);
  const [biome, setBiome] = useState<string | null>(null);

  const [biomesToFind, setBiomesToFind] = useState<number[] | null>(null);
  const [range, setRange] = useState<number | null>(null);

  const [structureToFind, setStructureToFind] = useState<number | null>(null);
  const [structuresToShow, setStructuresToShow] = useState<number[]>([]);

  const [isDrawingSeed, setIsDrawingSeed] = useState(false);

  const [isFindingSeed, setIsFindingSeed] = useState(false);
  const [seedFindingSpeed, setSeedFindingSpeed] = useState<boolean | number>(
    false
  );
  const [lastFoundSeed, setLastFoundSeed] = useState(0);

  const [menuToggled, setMenuToggled] = useState(false);

  const [showStrongholds, setShowStrongholds] = useState(true);
  const [showStructureCoords, setShowStructureCoords] = useState(true);
  const [showClaims, setShowClaims] = useState(true);
  const [showOverworldClaimsInNether, setShowOverworldClaimsInNether] =
    useState(true);

  const [showLegend, setShowLegend] = useState(false);

  //filterConfig,

  const findSeed = () => {
    if (range && range > 0) {
      setIsFindingSeed(true);
      setSeedFindingSpeed(0);
      const start = new Date();
      let events = 0;
      queueManager.seedUpdateCallback = () => {
        events++;
        const now = new Date();
        const speed = Math.floor(
          (events * 10000) / ((now.getTime() - start.getTime()) / 1000)
        );
        setSeedFindingSpeed(speed);
      };

      const callback = (foundSeed: string | number) => {
        foundSeed = "" + foundSeed;
        setSeed(foundSeed);
        setLastFoundSeed(parseInt(foundSeed));
        setIsFindingSeed(false);
      };

      if (biomesToFind && biomesToFind.length > 0 && structureToFind) {
        queueManager.findBiomesWithStructures(
          mcVersion,
          structureToFind,
          biomesToFind,
          -range,
          -range,
          range * 2,
          lastFoundSeed + 1,
          dimension,
          yHeight,
          9999,
          callback
        );
      } else if (biomesToFind && biomesToFind.length > 0) {
        queueManager.findBiomes(
          mcVersion,
          biomesToFind,
          -range,
          -range,
          range * 2,
          range * 2,
          lastFoundSeed + 1,
          dimension,
          yHeight,
          9999,
          callback
        );
      } else if (structureToFind) {
        queueManager.findStructures(
          mcVersion,
          structureToFind,
          -range,
          -range,
          range * 2,
          lastFoundSeed + 1,
          dimension,
          9999,
          callback
        );
      }
    }
  };

  const restartAll = () => {
    queueManager.restartAll();
    setIsFindingSeed(false);
  };

  const value: MapContextType = {
    serverId,
    setServerId,
    mcVersion,
    setMcVersion,
    seed,
    setSeed,
    dimension,
    setDimension,
    yHeight,
    setYHeight,
    queueManager,
    x,
    setX,
    z,
    setZ,
    biome,
    setBiome,
    biomesToFind,
    setBiomesToFind,
    range,
    setRange,
    structureToFind,
    setStructureToFind,
    structuresToShow,
    setStructuresToShow,
    isDrawingSeed,
    setIsDrawingSeed,
    isFindingSeed,
    setIsFindingSeed,
    seedFindingSpeed,
    setSeedFindingSpeed,
    lastFoundSeed,
    setLastFoundSeed,
    menuToggled,
    setMenuToggled,
    showStrongholds,
    setShowStrongholds,
    showStructureCoords,
    setShowStructureCoords,
    showClaims,
    setShowClaims,
    showOverworldClaimsInNether,
    setShowOverworldClaimsInNether,
    showLegend,
    setShowLegend,

    findSeed,
    restartAll,
  };

  return <MapContext.Provider value={value}>{children}</MapContext.Provider>;
}

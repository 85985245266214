import React, { useRef, useEffect, useState, useMemo } from "react";
import Select from "react-select";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";
import { LayerGroup, LayersControl, MapContainer } from "react-leaflet";
import { CRS } from "leaflet";

import { DrawSeed } from "../library/draw";
import "./MapPage.css";
import {
  VERSIONS,
  VERSIONS_OPTIONS,
  BIOMES,
  DIMENSIONS_OPTIONS,
  HEIGHT_OPTIONS,
} from "../util/constants";
import { setUrl, getRandomSeed, isNumeric } from "../util/functions";
import {
  DEFAULT_ZOOM,
  DRAW_SCALES,
  TILE_SIZE,
  worldCoordsToLatLng,
} from "../util/leafletHelpers";
import { useMapContext } from "../context/MapContext";
import { MapOptionsMenu } from "../components/MapOptionsMenu";
import { ShareSeedMenu } from "../components/ShareSeedMenu";
import { Donations } from "../components/Donations";
import { BiomesLayer } from "../components/BiomesLayer";
import { InfoControl } from "../components/InfoControl";
import db from "../db.json";

import "leaflet/dist/leaflet.css";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css";
import "leaflet-defaulticon-compatibility";
import { ImageMarker } from "../components/ImageMarker";
import { ClaimMarker } from "../components/ClaimMarker";

export type FilterConfigType = {
  ignoreCase: boolean;
  ignoreAccents: boolean;
  trim: boolean;
  matchFrom: "any" | "start" | undefined;
  stringify: (option: FilterOptionOption<any>) => string;
};

export default function MapPage() {
  const filterConfig: FilterConfigType = {
    ignoreCase: true,
    ignoreAccents: true,
    trim: true,
    matchFrom: "any",
    stringify: (option) => option.data.pureText,
  };

  const {
    serverId,
    setServerId,
    mcVersion,
    setMcVersion,
    seed,
    setSeed,
    dimension,
    setDimension,
    yHeight,
    setYHeight,
    queueManager,
    structuresToShow,
    isDrawingSeed,
    setIsDrawingSeed,
    isFindingSeed,
    seedFindingSpeed,
    menuToggled,
    setMenuToggled,
    showStructureCoords,
    showStrongholds,
    showClaims,
    showOverworldClaimsInNether,
    showLegend,
    setShowLegend,
    restartAll,
  } = useMapContext();

  const drawer = useRef<DrawSeed | null>(null);
  const [inputSeed, setInputSeed] = useState(seed);

  const server = useMemo(
    () => db.servers?.find((server) => server.id === serverId) ?? null,
    [serverId]
  );

  const [isRandomSeedButtonDisabled, setIsRandomSeedButtonDisabled] =
    useState(false);

  useEffect(() => {
    setInputSeed(seed);
  }, [setInputSeed, seed]);

  useEffect(() => {
    setIsRandomSeedButtonDisabled(isDrawingSeed);
  }, [setIsRandomSeedButtonDisabled, isDrawingSeed]);

  const drawSeed = (forced = false) => {
    setIsDrawingSeed(true);

    if (!drawer.current) {
      drawer.current = new DrawSeed(
        mcVersion,
        queueManager,
        document.createElement("canvas"),
        undefined,
        undefined,
        75,
        1
      );
    }
    if (forced) {
      drawer.current.clear();
    }
    drawer.current.setShowStructureCoords(showStructureCoords);
    drawer.current.setSeed(seed);
    drawer.current.setMcVersion(mcVersion);
    drawer.current.setDimension(dimension);
    drawer.current.setYHeight(yHeight);
    drawer.current.setStrongholdsShown(showStrongholds);
    drawer.current.setStructuresShown(structuresToShow);
    drawer.current.setShowClaims(showClaims);
    drawer.current.setShowOverworldClaimsInNether(showOverworldClaimsInNether);
    setUrl({ serverId, seed, mcVersion });
  };

  useEffect(() => {
    drawSeed(true);
    // eslint-disable-next-line
  }, [
    serverId,
    mcVersion,
    seed,
    dimension,
    yHeight,
    queueManager,
    structuresToShow,
    showStrongholds,
    showStructureCoords,
    showClaims,
    showOverworldClaimsInNether,
  ]);

  const setRandomSeed = () => {
    const randomSeed = getRandomSeed();
    setSeed(randomSeed);
    setServerId(null);
  };

  const seedFromString = function (s: string) {
    let h;
    for (let i = 0; i < s.length; i++) {
      h = (Math.imul(31, h ?? 0) + s.charCodeAt(i)) | 0;
    }
    return h ?? null;
  };

  const handleEnterSeed = () => {
    let newSeed = inputSeed;
    if (newSeed && newSeed !== seed) {
      if (!isNumeric(newSeed)) {
        newSeed = seedFromString(newSeed.toString()) ?? newSeed;
      }
      setSeed(newSeed);
      setServerId(null);
    }
  };

  const renderLegend = () => {
    if (showLegend && queueManager.COLORS) {
      const legend = BIOMES.map((x) => ({
        biome: x.label,
        color: queueManager.COLORS[x.value],
      }));
      if (legend.every((x) => x.color)) {
        return (
          <div className="legend flex-column">
            <div className="overflow-y-scroll flex-1">
              {legend.map((x) => {
                return (
                  <div key={x.biome} className="flex-row margin-3">
                    <div
                      style={{
                        marginRight: "10px",
                        width: "20px",
                        height: "20px",
                        backgroundColor: `rgba(${x?.color[0]}, ${x?.color[1]}, ${x?.color[2]}, ${x?.color[3]})`,
                      }}
                    ></div>
                    <div>{x?.biome}</div>
                  </div>
                );
              })}
            </div>
            <div className="margin-3">
              <button
                className="full-button"
                onClick={() => setShowLegend(false)}
              >
                Close
              </button>
            </div>
          </div>
        );
      }
    }
  };

  return (
    <>
      {isFindingSeed && (
        <div className="loading flex-column">
          <h1>Finding seed...</h1>
          <div className="loader"></div>
          <h3 className="margin-bottom-25">
            {seedFindingSpeed
              ? seedFindingSpeed + " seed/s"
              : "Calculating speed..."}
          </h3>
          <button
            className="stop-button padding-3"
            onClick={() => restartAll()}
          >
            STOP
          </button>
        </div>
      )}
      <div className="map-container flex-5 flex-row">
        <MapContainer
          className="h-full w-full"
          center={worldCoordsToLatLng(
            { x: server?.spawn.x ?? 0, z: server?.spawn.z ?? 0 },
            DEFAULT_ZOOM
          )}
          zoom={DEFAULT_ZOOM}
          minZoom={0}
          maxZoom={DRAW_SCALES.length - 1}
          zoomControl={false}
          scrollWheelZoom={true}
          crs={CRS.Simple}
          attributionControl={false}
        >
          {drawer.current && (
            <>
              <BiomesLayer
                tileSize={TILE_SIZE}
                drawer={drawer.current}
                queue={queueManager}
                mcVersion={mcVersion}
                seed={seed}
                dimension={dimension}
                yHeight={yHeight}
              />
              <InfoControl drawer={drawer.current} />
              <LayersControl position="topright">
                <LayersControl.Overlay checked name="Spawn">
                  <LayerGroup>
                    <ImageMarker
                      type="spawn"
                      worldCoords={{
                        x: server?.spawn.x ?? 0,
                        z: server?.spawn.z ?? 0,
                      }}
                    />
                  </LayerGroup>
                </LayersControl.Overlay>
                {server && (
                  <LayersControl.Overlay checked name="Claims">
                    <LayerGroup>
                      {server.claims.map((claim, i) => (
                        <ClaimMarker
                          key={i}
                          users={server.users}
                          claim={claim}
                        />
                      ))}
                    </LayerGroup>
                  </LayersControl.Overlay>
                )}
              </LayersControl>
            </>
          )}
        </MapContainer>
      </div>
      <div
        className={`panel-container flex-2 overflow-auto ${
          menuToggled ? "menu-toggled" : ""
        }`}
      >
        <div className="margin-3">
          <h3>Seed</h3>
        </div>
        <div className="flex-row margin-3">
          <input
            className="flex-3"
            value={inputSeed}
            onChange={(e) => setInputSeed(e?.target?.value)}
            onKeyPress={(e) => e.key === "Enter" && handleEnterSeed()}
          />
          <button
            style={{ borderLeft: "0px" }}
            className="flex-1"
            onClick={() => handleEnterSeed()}
          >
            GO
          </button>
        </div>
        <div className="margin-3">
          <button
            disabled={isRandomSeedButtonDisabled}
            className="full-button"
            onClick={() => setRandomSeed()}
          >
            Random seed
          </button>
        </div>
        <div className="margin-3 width-total">
          <div className="margin-3">Select MC version</div>
          <Select
            options={VERSIONS_OPTIONS}
            onChange={(val) => {
              if (val?.value !== undefined) {
                setMcVersion(val.value);
                setServerId(null);
              }
            }}
            value={VERSIONS_OPTIONS.find((v) => v.value === mcVersion)}
          />
        </div>
        <div className="margin-3 width-total">
          <div className="margin-3">Dimension</div>
          <Select
            options={DIMENSIONS_OPTIONS}
            onChange={(val) => {
              if (val?.value !== undefined) setDimension(val.value);
            }}
            value={DIMENSIONS_OPTIONS.find((v) => v.value === dimension)}
          />
        </div>
        {mcVersion > VERSIONS["1.18"] && (
          <div className="margin-3 width-total">
            <div className="margin-3">Biome layer</div>
            <Select
              options={HEIGHT_OPTIONS}
              onChange={(val) => {
                if (val?.value !== undefined) setYHeight(val.value);
              }}
              value={HEIGHT_OPTIONS.find((v) => v.value === yHeight)}
            />
          </div>
        )}
        <div className="margin-3">
          <button
            className="full-button"
            onClick={() => setShowLegend(!showLegend)}
          >
            {showLegend ? "Close legend" : "Show legend"}
          </button>
        </div>

        <hr />

        {false && <MapOptionsMenu filterConfig={filterConfig} />}

        <hr />

        <ShareSeedMenu />

        <hr />

        <Donations />
      </div>
      {renderLegend()}
    </>
  );
}

class CubiomesWorker extends Worker {
  name: string;
  busy: boolean;
  callback: Function | null;

  constructor(name: string, scriptURL: string | URL, options?: WorkerOptions) {
    super(scriptURL, options);
    this.name = name;
    this.busy = true;
    this.callback = null;
  }
}

export class QueueManager {
  path: string;
  numberOfWorkers: number;
  workers: CubiomesWorker[];
  workerCounter: number;
  COLORS: number[][];
  drawCache: { [key: string]: any };
  pixDim?: number;
  seedUpdateCallback: (() => void) | null;

  constructor(path: string, numberOfWorkers?: number) {
    this.path = path;
    this.numberOfWorkers =
      numberOfWorkers ?? navigator.hardwareConcurrency ?? 1;
    this.workers = [];
    this.workerCounter = 0;
    this.COLORS = [];
    this.drawCache = {};
    this.seedUpdateCallback = null;
    this._spawnWorkers();
    this.getColors();
  }

  getCacheKey(
    mcVersion: number,
    seed: string | number,
    startX: number,
    startY: number,
    widthX: number,
    widthY: number,
    dimension: number,
    yHeight: number
  ) {
    return (
      mcVersion +
      "-" +
      seed +
      "-" +
      startX +
      "-" +
      startY +
      "-" +
      widthX +
      "-" +
      widthY +
      "-" +
      dimension +
      "-" +
      yHeight
    );
  }

  draw(
    mcVersion: number,
    seed: string | number,
    startX: number,
    startY: number,
    widthX: number,
    widthY: number,
    dimension: number,
    yHeight: number,
    callback: (colors: number[][]) => void,
    force = false
  ) {
    if (!force) {
      const cacheKey = this.getCacheKey(
        mcVersion,
        seed,
        startX,
        startY,
        widthX,
        widthY,
        dimension,
        yHeight
      );
      const cachedColors = this.drawCache[cacheKey];
      if (cachedColors) {
        callback(cachedColors);
        return;
      }
    }
    for (let worker of this.workers) {
      if (!worker.busy) {
        worker.busy = true;
        worker.callback = callback;
        worker.postMessage({
          kind: "GET_AREA",
          data: {
            mcVersion,
            seed,
            startX,
            startY,
            widthX,
            widthY,
            dimension,
            yHeight,
          },
        });
        return;
      }
    }
    setTimeout(
      () =>
        this.draw(
          mcVersion,
          seed,
          startX,
          startY,
          widthX,
          widthY,
          dimension,
          yHeight,
          callback,
          true
        ),
      1
    );
  }

  findBiomes(
    mcVersion: number,
    biomes: any,
    x: number,
    z: number,
    widthX: number,
    widthZ: number,
    startingSeed: number,
    dimension: number,
    yHeight: number,
    threads: any,
    callback: (seed: string | number) => void
  ) {
    startingSeed = startingSeed ?? 0;
    for (let worker of this.workers) {
      if (!worker.busy && threads !== 0) {
        worker.busy = true;
        worker.callback = (seed: string | number) => {
          for (const worker of this.workers) {
            worker.terminate();
          }
          this.workers = [];
          this._spawnWorkers();
          callback(seed);
        };
        worker.postMessage({
          kind: "GET_BIOMES",
          data: {
            mcVersion,
            biomes,
            x,
            z,
            widthX,
            widthZ,
            startingSeed,
            dimension,
            yHeight,
          },
        });
        startingSeed += 1000000;
        threads--;
      }
    }
  }

  findSpawn(
    mcVersion: number,
    seed: string | number,
    callback: (x: number, z: number) => void
  ) {
    for (let worker of this.workers) {
      if (!worker.busy) {
        worker.busy = true;
        worker.callback = callback;
        worker.postMessage({
          kind: "GET_SPAWN",
          data: { mcVersion, seed },
        });
        return;
      }
    }
    setTimeout(() => this.findSpawn(mcVersion, seed, callback), 1);
  }

  findStrongholds(
    mcVersion: number,
    seed: string | number,
    howMany: number,
    callback: (data: { coords: number[][] }) => void
  ) {
    for (let worker of this.workers) {
      if (!worker.busy) {
        worker.busy = true;
        worker.callback = callback;
        worker.postMessage({
          kind: "GET_STRONGHOLDS",
          data: { mcVersion, seed, howMany },
        });
        return;
      }
    }
    setTimeout(
      () => this.findStrongholds(mcVersion, seed, howMany, callback),
      1
    );
  }

  findStructures(
    mcVersion: number,
    structType: number,
    x: any,
    z: any,
    range: any,
    startingSeed: number,
    dimension: number,
    threads: any,
    callback: (seed: string | number) => void
  ) {
    startingSeed = startingSeed ?? 0;
    for (let worker of this.workers) {
      if (!worker.busy && threads !== 0) {
        worker.busy = true;
        worker.callback = (seed: string | number) => {
          for (const worker of this.workers) {
            worker.terminate();
          }
          this.workers = [];
          this._spawnWorkers();
          callback(seed);
        };
        worker.postMessage({
          kind: "FIND_STRUCTURES",
          data: {
            mcVersion,
            structType,
            x,
            z,
            range,
            startingSeed,
            dimension,
          },
        });
        startingSeed += 1000000;
        threads--;
      }
    }
  }

  findBiomesWithStructures(
    mcVersion: number,
    structType: number,
    biomes: any,
    x: any,
    z: any,
    range: any,
    startingSeed: number,
    dimension: number,
    yHeight: number,
    threads: any,
    callback: (seed: string | number) => void
  ) {
    startingSeed = startingSeed ?? 0;
    for (let worker of this.workers) {
      if (!worker.busy && threads !== 0) {
        worker.busy = true;
        worker.callback = (seed: string | number) => {
          for (const worker of this.workers) {
            worker.terminate();
          }
          this.workers = [];
          this._spawnWorkers();
          callback(seed);
        };
        worker.postMessage({
          kind: "GET_BIOMES_WITH_STRUCTURES",
          data: {
            mcVersion,
            structType,
            biomes,
            x,
            z,
            range,
            startingSeed,
            dimension,
            yHeight,
          },
        });
        startingSeed += 1000000;
        threads--;
      }
    }
  }

  getStructuresInRegions(
    mcVersion: number,
    structType: number,
    seed: string | number,
    regionsRange: any,
    dimension: number,
    callback: (data: { coords: number[][] }) => void
  ) {
    for (let worker of this.workers) {
      if (!worker.busy) {
        worker.busy = true;
        worker.callback = callback;
        worker.postMessage({
          kind: "GET_STRUCTURES_IN_REGIONS",
          data: {
            mcVersion,
            structType,
            seed,
            regionsRange,
            dimension,
          },
        });
        return;
      }
    }
    setTimeout(
      () =>
        this.getStructuresInRegions(
          mcVersion,
          structType,
          seed,
          regionsRange,
          dimension,
          callback
        ),
      1
    );
  }

  getColors() {
    for (let worker of this.workers) {
      if (!worker.busy) {
        worker.busy = true;
        worker.postMessage({
          kind: "GET_COLORS",
        });
        return;
      }
    }
    setTimeout(() => this.getColors(), 1);
  }

  printStatus() {
    console.log(
      "Total workers: " +
        this.workers.length +
        " -  Busy: " +
        this.workers.filter((worker) => worker.busy).length
    );
  }

  killAll() {
    for (const worker of this.workers) {
      worker.terminate();
    }
    this.workers = [];
  }

  restartAll() {
    this.killAll();
    this._spawnWorkers();
  }

  _spawnWorkers() {
    for (let i = 0; i < this.numberOfWorkers; i++) {
      this._spawnWorker((worker: CubiomesWorker) => {
        this.workers.push(worker);
      });
    }
  }

  _spawnWorker(callback: (worker: CubiomesWorker) => void) {
    const name = "Worker_" + this.workerCounter++;
    const worker = new CubiomesWorker(name, this.path);
    worker.addEventListener("message", (e) =>
      this._commonListener(e, worker, callback)
    );
  }

  _commonListener(
    e: any,
    worker: CubiomesWorker,
    callback: (worker: CubiomesWorker) => void
  ) {
    if (e.data.kind === "DONE_LOADING") {
      worker.busy = false;
      callback(worker);
    } else if (e.data.kind === "DONE_GET_AREA") {
      const data = e.data.data;
      const cacheKey = this.getCacheKey(
        data.mcVersion,
        data.seed,
        data.startX,
        data.startY,
        data.widthX,
        data.widthY,
        data.dimension,
        data.yHeight
      );
      this.drawCache[cacheKey] = data.colors;
      if (worker.callback) worker.callback(data.colors);
      this._cleanWorker(worker);
    } else if (e.data.kind === "DONE_GET_BIOMES") {
      const data = e.data.data;
      if (worker.callback) worker.callback(data.seed);
      this._cleanWorker(worker);
    } else if (e.data.kind === "DONE_GET_SPAWN") {
      const data = e.data.data;
      if (worker.callback) worker.callback(data.x, data.z);
      this._cleanWorker(worker);
    } else if (e.data.kind === "DONE_GET_STRONGHOLDS") {
      const data = e.data.data;
      if (worker.callback) worker.callback(data);
      this._cleanWorker(worker);
    } else if (e.data.kind === "DONE_FIND_STRUCTURES") {
      const data = e.data.data;
      if (worker.callback) worker.callback(data.seed);
      this._cleanWorker(worker);
    } else if (e.data.kind === "DONE_GET_BIOMES_WITH_STRUCTURES") {
      const data = e.data.data;
      if (worker.callback) worker.callback(data.seed);
      this._cleanWorker(worker);
    } else if (e.data.kind === "DONE_GET_STRUCTURES_IN_REGIONS") {
      const data = e.data.data;
      if (worker.callback) worker.callback(data);
      this._cleanWorker(worker);
    } else if (e.data.kind === "DONE_GET_COLORS") {
      const data = e.data.data;
      this.COLORS = data.colors;
      this._cleanWorker(worker);
    } else if (e.data.kind === "SEED_UPDATE") {
      if (this.seedUpdateCallback) {
        this.seedUpdateCallback();
      }
    }
  }

  _cleanWorker(worker: CubiomesWorker) {
    worker.callback = null;
    worker.busy = false;
  }
}

import React, { useEffect, useState } from "react";
import { useMapContext } from "../context/MapContext";
import { copyToClipboard } from "../util/functions";

export function ShareSeedMenu() {
  const [buttonText, setButtonText] = useState("COPY");

  const { mcVersion, seed } = useMapContext();

  useEffect(() => {
    setButtonText("COPY");
  }, [setButtonText, mcVersion, seed]);

  return (
    <>
      <div className="margin-3">
        <h3>Share your seed:</h3>
      </div>
      <div className="flex-row margin-3">
        <input
          style={{ background: "white" }}
          className="flex-3"
          value={window.location.href}
          disabled
        />
        <button
          style={{ borderLeft: "0px" }}
          className="flex-1"
          onClick={() => {
            copyToClipboard(window.location.href);
            setButtonText("COPIED!");
          }}
        >
          {buttonText}
        </button>
      </div>
    </>
  );
}

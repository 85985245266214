import { useCallback, useEffect, useMemo, useState } from "react";
import { hashString } from "../util/functions";

const scripts: {
  [key: string]: { loaded: boolean; element: HTMLElement };
} = {};

export default function useScript({
  src = "",
  innerHTML = "",
  async = true,
  defer = true,
  loadImmediately = true,
  containerId = "",
}) {
  const id = useMemo(() => `_${hashString(src + innerHTML)}`, [src, innerHTML]);
  const [pending, setPending] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState<boolean | null>(null);
  const [loadNow, setLoadNow] = useState(loadImmediately);

  const createScript = useCallback(() => {
    setPending(true);

    function onScriptLoad() {
      setPending(false);
      setLoaded(true);
      setError(null);
      scripts[id].loaded = true;
    }

    const script = scripts[id];

    if (script !== undefined) {
      const scriptElement = script.element;

      if (script.loaded === true) {
        onScriptLoad();
      } else {
        const onScriptError = () => {
          setPending(false);
          setLoaded(true);
          setError(true);
        };

        scriptElement.addEventListener("load", onScriptLoad);
        scriptElement.addEventListener("error", onScriptError);

        return () => {
          scriptElement.removeEventListener("load", onScriptLoad);
          scriptElement.removeEventListener("error", onScriptError);
        };
      }
    } else {
      const scriptElement = document.createElement("script");

      scriptElement.id = id;
      if (src !== "") scriptElement.src = src;
      if (innerHTML !== "") scriptElement.innerHTML = innerHTML;

      scriptElement.async = async;
      scriptElement.defer = defer;

      scripts[id] = { element: scriptElement, loaded: false };

      const onScriptError = () => {
        delete scripts[id];

        scriptElement.remove();

        setPending(false);
        setLoaded(true);
        setError(true);
      };

      if (src !== "") {
        scriptElement.addEventListener("load", onScriptLoad);
        scriptElement.addEventListener("error", onScriptError);
      }

      if (containerId === "") {
        document.body.appendChild(scriptElement);
      } else {
        var container = document.querySelector(`#${containerId}`);
        container?.appendChild(scriptElement);
      }

      if (src === "") onScriptLoad();

      return () => {
        if (src !== "") {
          scriptElement.removeEventListener("load", onScriptLoad);
          scriptElement.removeEventListener("error", onScriptError);
        }
      };
    }
  }, [id, src, innerHTML, async, defer, containerId]);

  useEffect(() => {
    if (loadNow === true) {
      return createScript();
    }
  }, [loadNow, createScript]);

  const loadScript = useCallback(() => {
    setLoadNow(true);
  }, [setLoadNow]);

  const unloadScript = useCallback(() => {
    const script = scripts[id];

    if (script !== undefined) {
      script.element.remove();
      delete scripts[id];
    }
  }, [id]);

  return { loaded, error, pending, loadScript, unloadScript };
}

import React, { useState } from "react";
import { useMapEvents } from "react-leaflet";
import { LatLng } from "leaflet";

import {
  TileCoords,
  latLngToTileCoords,
  latLngToWorldCoords,
  zoomToDrawScale,
} from "../util/leafletHelpers";
import { DrawSeed } from "../library/draw";
import { useMapContext } from "../context/MapContext";

interface InfoControlProps {
  drawer: DrawSeed;
}

export const InfoControl: React.FC<InfoControlProps> = ({ drawer }) => {
  const { x, setX, z, setZ, biome, setBiome } = useMapContext();

  const [tileCoords, setTileCoords] = useState<TileCoords>({ x: 0, y: 0 });
  const [latLng, setLatLng] = useState<LatLng>(new LatLng(0, 0));
  const [drawScale, setDrawScale] = useState(0);

  const map = useMapEvents({
    mousemove(e) {
      setLatLng(e.latlng);
      setTileCoords(latLngToTileCoords(e.latlng, map.getZoom()));
      const worldCoords = latLngToWorldCoords(e.latlng, map.getZoom());
      const drawScale = zoomToDrawScale(map.getZoom());
      setDrawScale(drawScale);
      const biome = drawer.getBiome(
        Math.floor(worldCoords.x / drawScale),
        Math.floor(worldCoords.z / drawScale)
      );
      setX(Math.floor(worldCoords.x));
      setZ(Math.floor(worldCoords.z));
      setBiome(biome);
    },
  });

  return (
    <div className="leaflet-top leaflet-left">
      <div className="leaflet-control leaflet-bar">
        <div style={{ background: "#fff", padding: "10px" }}>
          <div>
            <b>X:</b> {x}, <b>Z:</b> {z}
          </div>
          {process.env.NODE_ENV !== "production" && (
            <>
              <div>
                <b>T: X:</b> {tileCoords.x}, <b>Y:</b> {tileCoords.y}
              </div>
              <div>
                <b>L: Lat:</b> {latLng.lat}, <b>Lng:</b> {latLng.lng}
              </div>
              <div>
                <b>D:</b> {drawScale}
              </div>
            </>
          )}
          <div>{biome}</div>
        </div>
      </div>
    </div>
  );
};

import { CRS, LatLng, Point } from "leaflet";

export interface TileCoords {
  /** north-south */
  x: number;
  /** east-west */
  y: number;
  /** zoom */
  z?: number;
}

export interface WorldCoords {
  /** north-south */
  x: number;
  /** elevation */
  y?: number;
  /** east-west */
  z: number;
}

export function tileCoordsToLatLng(
  tileCoords: TileCoords,
  zoom: number
): LatLng {
  const latLng = CRS.Simple.pointToLatLng(
    new Point(tileCoords.x * TILE_SIZE, tileCoords.y * TILE_SIZE),
    zoom
  );
  return latLng;
}

export function latLngToTileCoords(
  latLng: LatLng,
  zoom: number,
  tileWidth = 256,
  tileHeight = 256
): TileCoords {
  const point = CRS.Simple.latLngToPoint(latLng, zoom);
  return {
    x: point.x / tileWidth,
    y: point.y / tileHeight,
  };
}

export function worldCoordsToTileCoords(
  worldCoords: WorldCoords,
  zoom: number
): TileCoords {
  const drawScale = zoomToDrawScale(zoom);
  return {
    x: worldCoords.x / TILE_SIZE / drawScale,
    y: worldCoords.z / TILE_SIZE / drawScale,
    z: worldCoords.y,
  };
}

export function tileCoordsToWorldCoords(
  tileCoords: TileCoords,
  zoom: number
): WorldCoords {
  const drawScale = zoomToDrawScale(zoom);
  return {
    x: tileCoords.x * TILE_SIZE * drawScale,
    y: tileCoords.z,
    z: tileCoords.y * TILE_SIZE * drawScale,
  };
}

export function worldCoordsToLatLng(
  worldCoords: WorldCoords,
  zoom: number
): LatLng {
  const tileCoords = worldCoordsToTileCoords(worldCoords, zoom);
  return tileCoordsToLatLng(tileCoords, zoom);
}

export function latLngToWorldCoords(latLng: LatLng, zoom: number): WorldCoords {
  const tileCoords = latLngToTileCoords(latLng, zoom);
  return tileCoordsToWorldCoords(tileCoords, zoom);
}

// this corresponds to the scale parameter in api.c generate_area
// TODO: pass this parameter in through the api
export const TILE_SIZE = 256;
export const DEFAULT_ZOOM = 0;
export const DRAW_SCALES = [4];
//export const DEFAULT_ZOOM = 3;
//export const DRAW_SCALES = [256, 64, 16, 4, 1];

export function zoomToDrawScale(zoom: number) {
  return DRAW_SCALES[Math.trunc(zoom)] ?? DRAW_SCALES[DRAW_SCALES.length - 1];
}

export function drawScaleToZoom(drawScale: number) {
  const index = DRAW_SCALES.indexOf(drawScale);
  return index >= 0 ? index : DRAW_SCALES.length - 1;
}

export function convertWorldCoordsDimension(
  worldCoords: WorldCoords,
  fromDimension: number,
  toDimension: number
): WorldCoords {
  if (fromDimension === toDimension) return worldCoords;
  if (fromDimension === 0)
    return { x: worldCoords.x / 8, y: worldCoords.y, z: worldCoords.z / 8 };
  if (toDimension === 0)
    return { x: worldCoords.x * 8, y: worldCoords.y, z: worldCoords.z * 8 };
  throw worldCoords;
}

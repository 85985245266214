import React from "react";
import "./App.css";
import { BrowserRouter, Route, Link, Routes } from "react-router-dom";
import About from "./pages/About";
import MapPage from "./pages/MapPage";
import { MapContextProvider } from "./context/MapContext";

function App() {
  return (
    <BrowserRouter>
      <div className="flex-column height-total">
        <div className="header flex-row flex-align-center">
          <div className="flex-grow-1">
            <h3>
              <Link className="header-link" to="/">
                Dibs.World
              </Link>
            </h3>
          </div>
          <div className="flex-row">
            <Link className="margin-left-15 header-link" to="/about">
              About
            </Link>
          </div>
        </div>
        <div className="flex-row flex-grow-1 overflow-auto">
          <Routes>
            <Route path="/about" element={<About />} />
            <Route
              path="/"
              element={
                <MapContextProvider>
                  <MapPage />
                </MapContextProvider>
              }
            />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;

import React from "react";
import { Donations } from "../components/Donations";
import "./About.css";

export default function About() {
  return (
    <div className="width-total flex-column flex-align-center padding-15">
      <div className="about-container">
        <div className="flex-row flex-align-center">
          <h1>Dibs.World</h1>
          <small className="margin-left-15">(0.0.1)</small>
        </div>
        <h3>Who?</h3>
        <div className="paragraph">
          I'm{" "}
          <a
            href="https://twitter.com/MrSmilesGG"
            target="_blank"
            rel="noreferrer"
          >
            @MrSmilesGG
          </a>{" "}
          on Twitter, and @s_miles on Discord.
        </div>
        <h3>Can I help?</h3>
        <Donations />
        <h3>What?</h3>
        <div className="paragraph">
          This is a webapp based on a webapp (
          <a href="https://github.com/TrinTragula/seeder">Seeder</a> by{" "}
          <a href="https://github.com/TrinTragula">TrinTragula</a>) based on a
          library (<a href="https://github.com/Cubitect/cubiomes">cubiomes</a>{" "}
          by <a href="https://github.com/Cubitect">Cubitect</a>).
        </div>
        <h3>Why?</h3>
        <div className="paragraph">
          This site was made for a simple purpose - to easily be able look up
          claimed locations on a map of the Minecraft world. When all you have
          are a list of coordinates it can be difficult and time consuming to
          decide where to start building and avoid encroaching on other players'
          land.
        </div>
        <h3>The goal is to be able to:</h3>
        <ul>
          <li>View a world map for the seed on our Minecraft server</li>
          <li>Easily locate the spawn point</li>
          <li>
            Mark down important community locations such as:
            <ul>
              <li>Player claimed regions</li>
              <li>Shared community projects</li>
            </ul>
          </li>
          <li>Find biomes and structures</li>
        </ul>
      </div>
    </div>
  );
}

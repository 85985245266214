import React from "react";
import { Circle, Popup, Rectangle, useMap } from "react-leaflet";
import { LatLngBounds } from "leaflet";

import { useMapContext } from "../context/MapContext";
import {
  WorldCoords,
  convertWorldCoordsDimension,
  worldCoordsToLatLng,
} from "../util/leafletHelpers";

export interface User {
  username: string;
  colour: string;
}

export interface Claim {
  name: string | null;
  users: string[];
  dimension: number;
  points: WorldCoords[];
  confirmed: boolean;
}

interface ClaimMarkerProps {
  users: User[];
  claim: Claim;
}

export const ClaimMarker: React.FC<ClaimMarkerProps> = ({ users, claim }) => {
  const { dimension } = useMapContext();
  const map = useMap();

  const user =
    claim.users.length > 0
      ? users.find((user) => user.username === claim.users[0])
      : null;
  const colour = user?.colour ?? "white";

  const renderPopup = () => (
    <Popup>
      {claim.name && (
        <div>
          <b>{claim.name}</b>
        </div>
      )}
      {claim.users.length > 0 && <div>{claim.users.join(", ")}</div>}
    </Popup>
  );

  if (claim.dimension !== dimension) return null;
  if (claim.points.length === 1) {
    const p = convertWorldCoordsDimension(
      claim.points[0],
      claim.dimension,
      dimension
    );
    const radius = dimension === 0 ? 50 : 50 / 8;
    return (
      <Circle
        color={colour}
        center={worldCoordsToLatLng(p, map.getZoom())}
        radius={radius}
      >
        {renderPopup()}
      </Circle>
    );
  } else if (claim.points.length === 2) {
    const p1 = convertWorldCoordsDimension(
      claim.points[0],
      claim.dimension,
      dimension
    );
    const p2 = convertWorldCoordsDimension(
      claim.points[1],
      claim.dimension,
      dimension
    );

    const bounds = new LatLngBounds(
      worldCoordsToLatLng(p1, map.getZoom()),
      worldCoordsToLatLng(p2, map.getZoom())
    );
    return (
      <Rectangle color={colour} bounds={bounds}>
        {renderPopup()}
      </Rectangle>
    );
  }

  return null;
};

import React, { useEffect } from "react";
import useScript from "../hooks/useScript";

const sdkSource = "https://www.paypalobjects.com/donate/sdk/donate-sdk.js";
const renderButtonScript = `
PayPal.Donation.Button({
    env: "production",
    hosted_button_id: "7E87PQD74PSCW",
    image: {
        src: "https://www.paypalobjects.com/en_AU/i/btn/btn_donate_SM.gif",
        alt: "Donate with PayPal button",
        title: "PayPal - The safer, easier way to pay online!",
    },
    onComplete: function (params) {},
}).render("#paypal-donate-button");`;

export default function PayPalDonateButton() {
  const {
    loaded: sdkLoaded,
    error: sdkError,
    pending: sdkPending,
  } = useScript({
    src: sdkSource,
  });

  const {
    error: btnError,
    pending: btnPending,
    loadScript: btnLoadScript,
    unloadScript: btnUnloadScript,
  } = useScript({
    innerHTML: renderButtonScript,
    loadImmediately: false,
  });

  useEffect(() => {
    if (sdkLoaded === true) {
      btnLoadScript();
      return btnUnloadScript;
    }
  }, [sdkLoaded, btnLoadScript, btnUnloadScript]);

  return (
    <div id="paypal-donate-button-container">
      <div id="paypal-donate-button"></div>
      {sdkPending && btnPending ? (
        "..."
      ) : sdkError || btnError ? (
        <a
          href="https://www.paypal.com/donate/?hosted_button_id=7E87PQD74PSCW"
          target="_blank"
          rel="noreferrer"
        >
          Donate
        </a>
      ) : (
        ""
      )}
    </div>
  );
}

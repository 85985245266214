import React, { useMemo } from "react";
import { Marker, Popup, useMap } from "react-leaflet";

import { WorldCoords, worldCoordsToLatLng } from "../util/leafletHelpers";
import { MARKER_ICONS } from "../util/constants";

interface ImageMarkerProps {
  type: keyof typeof MARKER_ICONS;
  worldCoords: WorldCoords;
  name?: string;
}

export const ImageMarker: React.FC<ImageMarkerProps> = ({
  type,
  worldCoords,
  name,
}) => {
  const map = useMap();
  const latLng = useMemo(
    () => worldCoordsToLatLng(worldCoords, map.getZoom()),
    [map, worldCoords]
  );

  return (
    <Marker position={latLng} icon={MARKER_ICONS[type].icon}>
      <Popup>
        {name ?? MARKER_ICONS[type].name}
        <br />
        X: {worldCoords.x}, Z: {worldCoords.z}
      </Popup>
    </Marker>
  );
};

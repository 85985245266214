import React from "react";
import { Icon, Point } from "leaflet";

export const BIOMES = [
  { value: 0, label: "Ocean" },
  { value: 1, label: "Plains" },
  { value: 2, label: "Desert" },
  { value: 3, label: "Windswept Hills" },
  { value: 4, label: "Forest" },
  { value: 5, label: "Taiga" },
  { value: 6, label: "Swamp" },
  { value: 7, label: "River" },
  { value: 8, label: "Nether Wastes" },
  { value: 9, label: "The End" },
  { value: 10, label: "Frozen Ocean" },
  { value: 11, label: "Frozen River" },
  { value: 12, label: "Snowy Plains" },
  { value: 13, label: "Snowy Mountains" },
  { value: 14, label: "Mushroom Fields" },
  { value: 15, label: "Mushroom Field Shore" },
  { value: 16, label: "Beach" },
  { value: 17, label: "Desert Hills" },
  { value: 18, label: "Wooded Hills" },
  { value: 19, label: "Taiga Hills" },
  { value: 20, label: "Mountain Edge" },
  { value: 21, label: "Jungle" },
  { value: 22, label: "Jungle Hills" },
  { value: 23, label: "Sparse Jungle" },
  { value: 24, label: "Deep Ocean" },
  { value: 25, label: "Stony Shore" },
  { value: 26, label: "Snowy Beach" },
  { value: 27, label: "Birch Forest" },
  { value: 28, label: "Birch Forest Hills" },
  { value: 29, label: "Dark Forest" },
  { value: 30, label: "Snowy Taiga" },
  { value: 31, label: "Snowy Taiga Hills" },
  { value: 32, label: "Giant Tree Taiga" },
  { value: 33, label: "Giant Tree Taiga Hills" },
  { value: 34, label: "Windswept Forest" },
  { value: 35, label: "Savanna" },
  { value: 36, label: "Savanna Plateau" },
  { value: 37, label: "Badlands" },
  { value: 38, label: "Wooded Badlands" },
  { value: 39, label: "Badlands Plateau" },
  { value: 40, label: "Small End Islands" },
  { value: 41, label: "End Midlands" },
  { value: 42, label: "End Highlands" },
  { value: 43, label: "End Barrens" },
  { value: 44, label: "Warm Ocean" },
  { value: 45, label: "Lukewarm Ocean" },
  { value: 46, label: "Cold Ocean" },
  { value: 47, label: "Deep Warm Ocean" },
  { value: 48, label: "Deep Lukewarm Ocean" },
  { value: 49, label: "Deep Cold Ocean" },
  { value: 50, label: "Deep Frozen Ocean" },
  { value: 127, label: "The Void" },
  { value: 129, label: "Sunflower Plains" },
  { value: 130, label: "Desert Lakes" },
  { value: 131, label: "Windswept Gravelly Hills" },
  { value: 132, label: "Flower Forest" },
  { value: 133, label: "Taiga Mountains" },
  { value: 134, label: "Swamp Hills" },
  { value: 140, label: "Ice Spikes" },
  { value: 149, label: "Modified Jungle" },
  { value: 151, label: "Modified Jungle Edge" },
  { value: 155, label: "Tall Birch Forest" },
  { value: 156, label: "Tall Birch Hills" },
  { value: 157, label: "Dark Forest Hills" },
  { value: 158, label: "Snowy Taiga Mountains" },
  { value: 160, label: "Giant Spruce Taiga" },
  { value: 161, label: "Giant Spruce Taiga Hills" },
  { value: 162, label: "Modified Gravelly Mountains" },
  { value: 163, label: "Windswept Savanna" },
  { value: 164, label: "Shattered Savanna Plateau" },
  { value: 165, label: "Eroded Badlands" },
  { value: 166, label: "Modified Wooded Badlands Plateau" },
  { value: 167, label: "Modified Badlands Plateau" },
  { value: 168, label: "Bamboo Jungle" },
  { value: 169, label: "Bamboo Jungle Hills" },
  { value: 170, label: "Soul Sand Valley" },
  { value: 171, label: "Crimson Forest" },
  { value: 172, label: "Warped Forest" },
  { value: 173, label: "Basalt Delta" },
  { value: 174, label: "Dripstone Caves" },
  { value: 175, label: "Lush Caves" },
  { value: 177, label: "Meadow" },
  { value: 178, label: "Grove" },
  { value: 179, label: "Snowy Slopes" },
  { value: 180, label: "Jagged Peaks" },
  { value: 181, label: "Frozen Peaks" },
  { value: 182, label: "Stony Peaks" },
  { value: 183, label: "Deep Dark" },
  { value: 184, label: "Mangrove Swamp" },
  { value: 185, label: "Cherry Grove" },
];

// prettier-ignore
export const VERSIONS:{[key:string]:number} = {
  "Beta 1.7": 1,
  "Beta 1.8": 2,
  "1.0": 3,
  "1.1": 4,
  "1.2": 5,
  "1.3": 6,
  "1.4": 7,
  "1.5": 8,
  "1.6": 9,
  "1.7": 10,
  "1.8": 11,
  "1.9": 12,
  "1.10": 13,
  "1.11": 14,
  "1.12": 15,
  "1.13": 16,
  "1.14": 17,
  "1.15": 18,
  "1.16.1": 19,
  "1.16.5": 20,
  "1.17": 21,
  "1.18": 22,
  "1.19": 23,
  "1.19.2": 23,
  "1.19.4": 24,
  "1.20": 25,
};

export const VERSIONS_OPTIONS = Object.keys(VERSIONS).map((v) => ({
  label: v,
  value: VERSIONS[v],
}));

interface OptionLabelProps {
  name: string;
  src: string;
}

const OptionLabel: React.FC<OptionLabelProps> = ({ name, src }) => {
  return (
    <div className="flex-row flex-align-center">
      <img
        alt={name}
        src={src}
        style={{ paddingRight: "15px" }}
        height="30px"
        width="30px"
      />
      {name}
    </div>
  );
};

export const STRUCTURES_OPTIONS = [
  {
    value: 1,
    pureText: "Desert Pyramid",
    label: <OptionLabel name="Desert Pyramid" src="/img/temple.png" />,
  },
  {
    value: 2,
    pureText: "Jungle Pyramid",
    label: <OptionLabel name="Jungle Pyramid" src="/img/jungle.png" />,
  },
  {
    value: 3,
    pureText: "Swamp Hut",
    label: <OptionLabel name="Swamp Hut" src="/img/hut.png" />,
  },
  {
    value: 4,
    pureText: "Igloo",
    label: <OptionLabel name="Igloo" src="/img/igloo.png" />,
  },
  {
    value: 5,
    pureText: "Village",
    label: <OptionLabel name="Village" src="/img/village.png" />,
  },
  {
    value: 6,
    pureText: "Ocean Ruin",
    label: <OptionLabel name="Ocean Ruin" src="/img/ocean.png" />,
  },
  {
    value: 7,
    pureText: "Shipwreck",
    label: <OptionLabel name="Shipwreck" src="/img/wood.jpg" />,
  },
  {
    value: 8,
    pureText: "Monument",
    label: <OptionLabel name="Monument" src="/img/guardian.png" />,
  },
  {
    value: 9,
    pureText: "Mansion",
    label: <OptionLabel name="Mansion" src="/img/mansion.png" />,
  },
  {
    value: 10,
    pureText: "Outpost",
    label: <OptionLabel name="Outpost" src="/img/outpost.png" />,
  },
  {
    value: 11,
    pureText: "Ruined Portal",
    label: <OptionLabel name="Ruined Portal" src="/img/portal.png" />,
  },
  // 12 Ruined_Portal_N,
  {
    value: 13,
    pureText: "Ancient City",
    label: <OptionLabel name="Ancient City" src="/img/ancient_city.png" />,
  },
  // 14 Treasure,
  // 15 Mineshaft,
  {
    value: 16,
    pureText: "Fortress",
    label: <OptionLabel name="Fortress" src="/img/fortress.png" />,
  },
  {
    value: 17,
    pureText: "Bastion",
    label: <OptionLabel name="Bastion" src="/img/bastion.png" />,
  },
  {
    value: 18,
    pureText: "End City",
    label: <OptionLabel name="End City" src="/img/end_city.png" />,
  },
  {
    value: 19,
    pureText: "End Gateway",
    label: <OptionLabel name="End Gateway" src="/img/end_gateway.png" />,
  },
];

export const DIMENSIONS_OPTIONS = [
  {
    value: 0,
    label: <OptionLabel name="Overworld" src="/img/grass.png" />,
  },
  {
    value: -1,
    label: <OptionLabel name="Nether" src="/img/nether_bricks.png" />,
  },
  {
    value: 1,
    label: <OptionLabel name="End" src="/img/endstone.png" />,
  },
];

export const HEIGHT_OPTIONS = [
  { value: 320, label: "Max height (Y=320)" },
  { value: 256, label: "Mountain tops (Y=256)" },
  { value: 192, label: "Clouds (Y=192)" },
  { value: 62, label: "Sea level (Y=62)" },
  { value: 42, label: "Underground (Y=42)" },
  { value: 0, label: "Deep underground (Y=0)" },
  { value: -30, label: "Deeper underground (Y=-30)" },
  { value: -70, label: "Bedrock (Y=-64)" },
];

function createMarkerIcon(imageUrl: string, width = 30, height = 30) {
  return new Icon({
    iconUrl: imageUrl,
    iconSize: new Point(width, height),
  });
}

export const MARKER_ICONS = {
  ancient_city: {
    name: "Ancient City",
    icon: createMarkerIcon("/img/ancient_city.png"),
  },
  bastion: {
    name: "Bastion",
    icon: createMarkerIcon("/img/bastion.png"),
  },
  end_city: {
    name: "End City",
    icon: createMarkerIcon("/img/end_city.png"),
  },
  end_gateway: {
    name: "End Gateway",
    icon: createMarkerIcon("/img/end_gateway.png"),
  },
  endstone: {
    name: "Endstone",
    icon: createMarkerIcon("/img/endstone.png"),
  },
  eye: {
    name: "Eye",
    icon: createMarkerIcon("/img/eye.png"),
  },
  fortress: {
    name: "Fortress",
    icon: createMarkerIcon("/img/fortress.png"),
  },
  grass: {
    name: "Grass",
    icon: createMarkerIcon("/img/grass.png"),
  },
  guardian: {
    name: "Guardian",
    icon: createMarkerIcon("/img/guardian.png"),
  },
  hut: {
    name: "Hut",
    icon: createMarkerIcon("/img/hut.png"),
  },
  igloo: {
    name: "Igloo",
    icon: createMarkerIcon("/img/igloo.png"),
  },
  jungle: {
    name: "Jungle",
    icon: createMarkerIcon("/img/jungle.png"),
  },
  mansion: {
    name: "Mansion",
    icon: createMarkerIcon("/img/mansion.png"),
  },
  nether_bricks: {
    name: "Nether Bricks",
    icon: createMarkerIcon("/img/nether_bricks.png"),
  },
  ocean: {
    name: "Ocean",
    icon: createMarkerIcon("/img/ocean.png"),
  },
  outpost: {
    name: "Outpost",
    icon: createMarkerIcon("/img/outpost.png"),
  },
  portal: {
    name: "Portal",
    icon: createMarkerIcon("/img/portal.png"),
  },
  spawn: {
    name: "Spawn",
    icon: createMarkerIcon("/img/spawn.png"),
  },
  temple: {
    name: "Temple",
    icon: createMarkerIcon("/img/temple.png"),
  },
  treasure: {
    name: "Treasure",
    icon: createMarkerIcon("/img/treasure.png"),
  },
  village: {
    name: "Village",
    icon: createMarkerIcon("/img/village.png"),
  },
  wood: {
    name: "Wood",
    icon: createMarkerIcon("/img/wood.png"),
  },
};

import React from "react";

export default function KoFiDonateButton() {
  return (
    <div id="kofi-donate-button-container">
      <a href="https://ko-fi.com/M4M5DBFRJ" target="_blank" rel="noreferrer">
        <img
          height="36"
          style={{ border: "0px", height: "36px" }}
          src="https://cdn.ko-fi.com/cdn/kofi1.png?v=3"
          alt="Buy Me a Coffee at ko-fi.com"
        />
      </a>
    </div>
  );
}

import React from "react";
import KoFiDonateButton from "./KoFiDonateButton";
import PayPalDonateButton from "./PayPalDonateButton";

export function Donations() {
  return (
    <div className="paragraph">
      <div className="flex-row flex-align-center">
        <div className="margin-3">
          Your support is greatly appreciated and helps to keep this website
          online!
        </div>
      </div>
      <div className="flex-row flex-align-center">
        <div className="margin-3">PayPal:</div>
        <PayPalDonateButton />
      </div>
      <div className="flex-row flex-align-center">
        <div className="margin-3">Ko-Fi:</div>
        <KoFiDonateButton />
      </div>
    </div>
  );
}

import type { QueueManager } from "./queue";
import { BIOMES } from "../util/constants";
import db from "../db.json";

export class DrawSeed {
  mcVersion: number;
  dimension: number;
  yHeight: number;
  queue: QueueManager;
  canvas: HTMLCanvasElement;
  biomesDict: { [x: number]: { [y: number]: number[] } };
  ctx: CanvasRenderingContext2D | null;
  drawDim: number;
  pixDim: number;
  offsetX: number;
  offsetZ: number;
  spawnShown: boolean;
  spawnX: number | null;
  spawnZ: number | null;
  strongholdsShown: boolean;
  strongholds: number[][] | null;
  structuresShown: { [key: number]: boolean };
  structures: { [key: number]: number[][] };
  toDraw: number;
  showStructureCoords: boolean;
  showClaims: boolean;
  showOverworldClaimsInNether: boolean;
  seed: string | number | null;
  currenTicket: string | null = null;

  constructor(
    mcVersion: number,
    queue: QueueManager,
    canvas: HTMLCanvasElement,
    onclick?: (x: number, y: number, biome: string) => void,
    onmousemove?: (x: number, y: number, biome: string) => void,
    drawDim?: number,
    pixDim?: number,
    offsetX?: number,
    offsetZ?: number
  ) {
    this.mcVersion = mcVersion;
    this.seed = null;
    this.dimension = 0; // Overworld
    this.yHeight = 320; // Top of the world
    this.queue = queue;
    this.canvas = canvas;
    this.biomesDict = {};
    this.ctx = this.canvas.getContext("2d");
    this.drawDim = drawDim ?? 50;
    this.pixDim = pixDim ?? 1;
    this.offsetX = offsetX ?? Math.floor(this.canvas.width / this.drawDim / 2);
    this.offsetZ = offsetZ ?? Math.floor(this.canvas.height / this.drawDim / 2);
    this.spawnShown = false;
    this.spawnX = null;
    this.spawnZ = null;
    this.strongholdsShown = false;
    this.strongholds = null;
    this.structuresShown = {};
    this.structures = {};
    this.toDraw = 0;
    this.showStructureCoords = true;
    this.showClaims = true;
    this.showOverworldClaimsInNether = true;

    if (onclick) {
      this.canvas.onclick = (e) => {
        const [x, y, biome] = this.getBiomeAndPos(e);
        if (x && y && biome) {
          onclick(x, y, biome);
        }
      };
    }

    if (onmousemove) {
      this.canvas.onmousemove = (e) => {
        const [x, y, biome] = this.getBiomeAndPos(e);
        if (x && y && biome) {
          onmousemove(x, y, biome);
        }
      };
    }
  }

  getOffsetFromCoords(x: number, z: number) {
    const chunkX = Math.floor(x / 4);
    const chunkZ = Math.floor(z / 4);
    return {
      x:
        Math.floor(-chunkX / this.drawDim) +
        Math.floor(this.canvas.width / this.drawDim / 2),
      z:
        Math.floor(-chunkZ / this.drawDim) +
        Math.floor(this.canvas.height / this.drawDim / 2),
    };
  }

  clear(offsetX?: number, offsetZ?: number) {
    this.biomesDict = {};
    this.spawnX = null;
    this.spawnZ = null;
    this.strongholds = null;
    this.structures = {};
    this.strongholdsShown = false;
    this.spawnShown = false;
    this.showClaims = true;
    this.offsetX = offsetX ?? Math.floor(this.canvas.width / this.drawDim / 2);
    this.offsetZ = offsetZ ?? Math.floor(this.canvas.height / this.drawDim / 2);
  }

  setShowStructureCoords(value: boolean) {
    if (value !== this.showStructureCoords) {
      this.showStructureCoords = value;
    }
  }

  setSeed(seed: string | number) {
    if (this.seed !== seed) {
      this.seed = seed;
    }
  }

  setDimension(dimension: number) {
    if (this.dimension !== dimension) {
      this.dimension = dimension;
    }
  }

  setYHeight(yHeight: number) {
    if (this.yHeight !== yHeight) {
      this.yHeight = yHeight;
    }
  }

  setMcVersion(mcVersion: number) {
    this.mcVersion = mcVersion;
  }

  findSpawn(
    callback: (seed: string | number, spawnX: number, spawnZ: number) => void
  ) {
    if (!this.seed) return;
    const server = db.servers.find((o) => o.seed === this.seed);
    if (
      server !== undefined &&
      server !== null &&
      server.spawn !== undefined &&
      server.spawn !== null
    ) {
      this.spawnX = server.spawn.x;
      this.spawnZ = server.spawn.z;
      this.spawnShown = true;
    }

    if (this.spawnX && this.spawnZ) {
      callback(this.seed, this.spawnX, this.spawnZ);
      return;
    }

    this.queue.findSpawn(this.mcVersion, this.seed, (x, z) => {
      this.spawnX = x;
      this.spawnZ = z;
      this.spawnShown = true;
      if (this.seed) callback(this.seed, this.spawnX, this.spawnZ);
    });
  }

  findStrongholds(
    callback: (seed: string | number, strongholds: number[][]) => void
  ) {
    if (!this.seed) return;
    if (this.strongholds && this.strongholds.length > 0) {
      callback(this.seed, this.strongholds);
      return;
    }
    this.queue.findStrongholds(this.mcVersion, this.seed, 150, ({ coords }) => {
      this.strongholds = coords;
      if (this.seed) callback(this.seed, this.strongholds);
    });
  }

  setStrongholdsShown(value: boolean) {
    if (value !== this.strongholdsShown) {
      this.strongholdsShown = value;
    }
  }

  findStructure(
    structType: number,
    callback: (seed: string | number, structures?: number[][]) => void
  ) {
    if (!this.seed) return;
    if (
      this.structures &&
      this.structures[structType] &&
      this.structures[structType].length > 0
    ) {
      callback(this.seed, this.structures[structType]);
      this.structuresShown[structType] = true;
      return;
    }
    this.queue.getStructuresInRegions(
      this.mcVersion,
      structType,
      this.seed,
      50,
      this.dimension,
      ({ coords }: { coords: number[][] }) => {
        this.structures[structType] = coords;
        if (this.seed) callback(this.seed, this.structures[structType]);
        this.structuresShown[structType] = true;
      }
    );
  }

  setStructuresShown(structTypes: number[]) {
    this.structuresShown = {};
    for (const structType of structTypes) {
      this.structuresShown[structType] = true;
    }
  }

  setShowClaims(value: boolean) {
    if (value !== this.showClaims) {
      this.showClaims = value;
    }
  }

  setShowOverworldClaimsInNether(value: boolean) {
    if (value !== this.showOverworldClaimsInNether) {
      this.showOverworldClaimsInNether = value;
    }
  }

  spiral(
    xDim: number,
    yDim: number,
    callback: (x: number, y: number) => boolean
  ) {
    let x = 0;
    let y = 0;
    let dx = 0;
    let dy = -1;
    const baseX = Math.ceil(xDim / 2) - 1;
    const baseY = Math.ceil(yDim / 2) - 1;
    for (let i = 0; i < Math.pow(Math.max(xDim, yDim), 2); i++) {
      if (
        -(xDim / 2) < x &&
        x <= xDim / 2 &&
        -(yDim / 2) < y &&
        y <= yDim / 2
      ) {
        var shouldStop = callback(baseX + x, baseY + y);
        if (shouldStop) return;
      }
      if (x === y || (x < 0 && x === -y) || (x > 0 && x === 1 - y)) {
        const tempDy = dy;
        dy = dx;
        dx = -tempDy;
      }
      x = x + dx;
      y = y + dy;
    }
    return;
  }

  draw(callback?: () => void) {
    if (!this.seed) return;
    const ticket =
      this.mcVersion +
      "-" +
      this.seed +
      "-" +
      this.offsetX +
      "-" +
      this.offsetZ +
      "-" +
      this.drawDim +
      "-" +
      this.pixDim +
      "-" +
      this.dimension +
      "-" +
      this.yHeight;
    this.currenTicket = ticket;
    console.time("Drawing seed");
    if (this.toDraw > 0) {
      setTimeout(() => this.draw(), 10);
    } else {
      this.ctx?.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.biomesDict = {};
      const xSize = Math.ceil(this.canvas.width / (this.drawDim * this.pixDim));
      const ySize = Math.ceil(
        this.canvas.height / (this.drawDim * this.pixDim)
      );
      this.toDraw = xSize * ySize;
      let widthX = this.drawDim;
      let widthY = this.drawDim;
      this.spiral(xSize, ySize, (i, j) => {
        if (!this.seed) return true;
        let startX = Math.floor(
          this.drawDim * (i - this.offsetX / this.pixDim)
        );
        let startY = Math.floor(
          this.drawDim * (j - this.offsetZ / this.pixDim)
        );
        let drawStartX = this.drawDim * this.pixDim * i;
        let drawStartY = this.drawDim * this.pixDim * j;
        if (this.currenTicket !== ticket) {
          this.toDraw -= xSize * ySize;
          return true;
        }
        this.queue.draw(
          this.mcVersion,
          this.seed,
          startX,
          startY,
          widthX,
          widthY,
          this.dimension,
          this.yHeight,
          (colors: number[][]) => {
            if (this.currenTicket !== ticket) {
              this.toDraw -= xSize * ySize;
              return true;
            }
            this._drawLoop(
              this.ctx,
              colors,
              startX,
              startY,
              drawStartX,
              drawStartY,
              widthX,
              widthY
            );
            if (this.toDraw === 1) {
              this.drawStructures();
              if (callback) {
                callback();
              }
              console.timeEnd("Drawing seed");
            }
            this.toDraw--;
          }
        );
        return false; // continue, don't stop iterating
      });
    }
  }

  zoom() {
    if (this.pixDim < 5) {
      this.pixDim++;
      this.queue.pixDim = this.pixDim;
      this.draw();
    }
  }

  dezoom() {
    if (this.pixDim > 1) {
      this.pixDim--;
      this.pixDim = this.pixDim || 1;
      this.queue.pixDim = this.pixDim;
      this.draw();
    }
  }

  down() {
    if (!this.seed) return;
    if (this.toDraw === 0) {
      this.offsetZ--;
      const xSize = Math.ceil(this.canvas.width / (this.drawDim * this.pixDim));
      this.toDraw = xSize;

      const tempCanvas = document.createElement("canvas");
      const tempCtx = tempCanvas.getContext("2d");
      tempCanvas.width = this.canvas.width;
      tempCanvas.height = this.canvas.height;
      tempCtx?.drawImage(
        this.canvas,
        0,
        0,
        this.canvas.width,
        this.canvas.height
      );

      if (this.ctx) {
        this.ctx.fillStyle = "#333333";
        this.ctx.fillRect(
          0,
          this.canvas.height - this.drawDim,
          this.canvas.width,
          this.canvas.height
        );
        this.ctx.translate(0, -this.drawDim);
        this.ctx.drawImage(tempCanvas, 0, 0);
        this.ctx.translate(0, this.drawDim);
      }

      const ySize = this.canvas.height / (this.drawDim * this.pixDim);
      for (let i = 0; i < xSize; i++) {
        let startX = Math.floor(
          this.drawDim * (i - this.offsetX / this.pixDim)
        );
        let startY = Math.floor(
          this.drawDim * (ySize - 1 - this.offsetZ / this.pixDim)
        );
        let widthX = this.drawDim;
        let widthY = this.drawDim;
        let drawStartX = this.drawDim * this.pixDim * i;
        let drawStartY = this.canvas.height - this.drawDim * this.pixDim;
        this.queue.draw(
          this.mcVersion,
          this.seed,
          startX,
          startY,
          widthX,
          widthY,
          this.dimension,
          this.yHeight,
          (colors) => {
            this._drawLoop(
              this.ctx,
              colors,
              startX,
              startY,
              drawStartX,
              drawStartY,
              widthX,
              widthY
            );
            if (this.toDraw === 1) {
              this.drawStructures();
            }
            this.toDraw--;
          }
        );
      }
    }
  }

  up() {
    if (!this.seed) return;
    if (this.toDraw === 0) {
      this.offsetZ++;
      const xSize = Math.ceil(this.canvas.width / (this.drawDim * this.pixDim));
      this.toDraw = xSize;

      const tempCanvas = document.createElement("canvas");
      const tempCtx = tempCanvas.getContext("2d");
      tempCanvas.width = this.canvas.width;
      tempCanvas.height = this.canvas.height;
      tempCtx?.drawImage(
        this.canvas,
        0,
        0,
        this.canvas.width,
        this.canvas.height
      );

      if (this.ctx) {
        this.ctx.fillStyle = "#333333";
        this.ctx.fillRect(0, 0, this.canvas.width, this.drawDim);
        this.ctx.translate(0, this.drawDim);
        this.ctx.drawImage(tempCanvas, 0, 0);
        this.ctx.translate(0, -this.drawDim);
      }

      for (let i = 0; i < xSize; i++) {
        let startX = Math.floor(
          this.drawDim * (i - this.offsetX / this.pixDim)
        );
        let startY = Math.floor(this.drawDim * -(this.offsetZ / this.pixDim));
        let widthX = this.drawDim;
        let widthY = this.drawDim;
        let drawStartX = this.drawDim * this.pixDim * i;
        let drawStartY = 0;
        this.queue.draw(
          this.mcVersion,
          this.seed,
          startX,
          startY,
          widthX,
          widthY,
          this.dimension,
          this.yHeight,
          (colors) => {
            this._drawLoop(
              this.ctx,
              colors,
              startX,
              startY,
              drawStartX,
              drawStartY,
              widthX,
              widthY
            );
            if (this.toDraw === 1) {
              this.drawStructures();
            }
            this.toDraw--;
          }
        );
      }
    }
  }

  right() {
    if (!this.seed) return;
    if (this.toDraw === 0) {
      this.offsetX--;
      const ySize = Math.ceil(
        this.canvas.height / (this.drawDim * this.pixDim)
      );
      this.toDraw = ySize;

      const tempCanvas = document.createElement("canvas");
      const tempCtx = tempCanvas.getContext("2d");
      tempCanvas.width = this.canvas.width;
      tempCanvas.height = this.canvas.height;
      tempCtx?.drawImage(
        this.canvas,
        0,
        0,
        this.canvas.width,
        this.canvas.height
      );

      if (this.ctx) {
        this.ctx.fillStyle = "#333333";
        this.ctx.fillRect(
          this.canvas.width - this.drawDim,
          0,
          this.canvas.width,
          this.canvas.height
        );
        this.ctx.translate(-this.drawDim, 0);
        this.ctx.drawImage(tempCanvas, 0, 0);
        this.ctx.translate(this.drawDim, 0);
      }

      const xSize = this.canvas.width / (this.drawDim * this.pixDim);
      for (let j = 0; j < ySize; j++) {
        let startX = Math.floor(
          this.drawDim * (xSize - 1 - this.offsetX / this.pixDim)
        );
        let startY = Math.floor(
          this.drawDim * (j - this.offsetZ / this.pixDim)
        );
        let widthX = this.drawDim;
        let widthY = this.drawDim;
        let drawStartX = this.canvas.width - this.drawDim * this.pixDim;
        let drawStartY = this.drawDim * this.pixDim * j;
        this.queue.draw(
          this.mcVersion,
          this.seed,
          startX,
          startY,
          widthX,
          widthY,
          this.dimension,
          this.yHeight,
          (colors) => {
            this._drawLoop(
              this.ctx,
              colors,
              startX,
              startY,
              drawStartX,
              drawStartY,
              widthX,
              widthY
            );
            if (this.toDraw === 1) {
              this.drawStructures();
            }
            this.toDraw--;
          }
        );
      }
    }
  }

  left() {
    if (!this.seed) return;
    if (this.toDraw === 0) {
      this.offsetX++;
      const ySize = Math.ceil(
        this.canvas.height / (this.drawDim * this.pixDim)
      );
      this.toDraw = ySize;

      const tempCanvas = document.createElement("canvas");
      const tempCtx = tempCanvas.getContext("2d");
      tempCanvas.width = this.canvas.width;
      tempCanvas.height = this.canvas.height;
      tempCtx?.drawImage(
        this.canvas,
        0,
        0,
        this.canvas.width,
        this.canvas.height
      );

      if (this.ctx) {
        this.ctx.fillStyle = "#333333";
        this.ctx.fillRect(0, 0, this.drawDim, this.canvas.height);
        this.ctx.translate(this.drawDim, 0);
        this.ctx.drawImage(tempCanvas, 0, 0);
        this.ctx.translate(-this.drawDim, 0);
      }

      for (let j = 0; j < ySize; j++) {
        let startX = Math.floor(this.drawDim * -(this.offsetX / this.pixDim));
        let startY = Math.floor(
          this.drawDim * (j - this.offsetZ / this.pixDim)
        );
        let widthX = this.drawDim;
        let widthY = this.drawDim;
        let drawStartX = 0;
        let drawStartY = this.drawDim * this.pixDim * j;
        this.queue.draw(
          this.mcVersion,
          this.seed,
          startX,
          startY,
          widthX,
          widthY,
          this.dimension,
          this.yHeight,
          (colors) => {
            this._drawLoop(
              this.ctx,
              colors,
              startX,
              startY,
              drawStartX,
              drawStartY,
              widthX,
              widthY
            );
            if (this.toDraw === 1) {
              this.drawStructures();
            }
            this.toDraw--;
          }
        );
      }
    }
  }

  drawText(
    text: string,
    x: number,
    z: number,
    xOffset = 0,
    zOffset = 30,
    fontSize = 12
  ) {
    if (this.showStructureCoords && this.ctx) {
      this.ctx.font = `bold ${fontSize}px Courier New`;
      this.ctx.textAlign = "center";
      this.ctx.fillStyle = "#ffffffbb";

      const textWidth = this.ctx.measureText(text).width;

      this.ctx.fillRect(
        x - textWidth / 2 - 1,
        z + zOffset - fontSize,
        textWidth + 1,
        Math.floor(fontSize * 1.5)
      );

      this.ctx.fillStyle = "black";
      this.ctx.fillText(text, x + xOffset, z + zOffset);
    }
  }

  drawStructures() {
    if (!this.ctx) return;

    if (
      this.spawnShown &&
      this.dimension === 0 &&
      this.spawnX != null &&
      this.spawnZ != null
    ) {
      let drawX =
        Math.floor(this.spawnX / 4) * this.pixDim + this.offsetX * this.drawDim;
      let drawZ =
        Math.floor(this.spawnZ / 4) * this.pixDim + this.offsetZ * this.drawDim;
      if (
        drawX > 0 &&
        drawZ > 0 &&
        drawX < this.canvas.width &&
        drawZ < this.canvas.height
      ) {
        const image = new Image(32, 30);
        image.src = this.spawnImage;
        if (image.complete) {
          this.ctx.drawImage(image, drawX - 16, drawZ - 15, 32, 30);
        } else {
          const offsetX = this.offsetX;
          const offsetZ = this.offsetZ;
          const pixDim = this.pixDim;
          image.onload = () => {
            if (
              this.offsetX === offsetX &&
              offsetZ === this.offsetZ &&
              pixDim === this.pixDim
            ) {
              this.ctx?.drawImage(image, drawX - 16, drawZ - 15, 32, 30);
            }
          };
        }
        this.drawText(`(${this.spawnX}, ${this.spawnZ})`, drawX, drawZ);
      }
    }

    if (
      this.strongholdsShown &&
      this.dimension === 0 &&
      this.strongholds &&
      this.strongholds.length > 0
    ) {
      for (const stronghold of this.strongholds) {
        let drawX =
          Math.floor(stronghold[0] / 4) * this.pixDim +
          this.offsetX * this.drawDim;
        let drawZ =
          Math.floor(stronghold[1] / 4) * this.pixDim +
          this.offsetZ * this.drawDim;
        if (
          drawX > 0 &&
          drawZ > 0 &&
          drawX < this.canvas.width &&
          drawZ < this.canvas.height
        ) {
          const image = new Image(30, 30);
          image.src = this.eyeImage;
          if (image.complete) {
            this.ctx.drawImage(image, drawX - 15, drawZ - 15, 30, 30);
          } else {
            const offsetX = this.offsetX;
            const offsetZ = this.offsetZ;
            const pixDim = this.pixDim;
            image.onload = () => {
              if (
                this.offsetX === offsetX &&
                offsetZ === this.offsetZ &&
                pixDim === this.pixDim
              ) {
                this.ctx?.drawImage(image, drawX - 15, drawZ - 15, 30, 30);
              }
            };
          }
          this.drawText(`(${stronghold[0]}, ${stronghold[1]})`, drawX, drawZ);
        }
      }
    }

    if (this.structuresShown) {
      for (let structureKey of Object.keys(this.structuresShown).map(Number)) {
        if (this.structures[structureKey]) {
          for (const structure of this.structures[structureKey]) {
            let drawX =
              Math.floor(structure[0] / 4) * this.pixDim +
              this.offsetX * this.drawDim;
            let drawZ =
              Math.floor(structure[1] / 4) * this.pixDim +
              this.offsetZ * this.drawDim;
            if (
              drawX > 0 &&
              drawZ > 0 &&
              drawX < this.canvas.width &&
              drawZ < this.canvas.height
            ) {
              const image = new Image(30, 30);
              image.src = this.images[structureKey];
              if (image.complete) {
                this.ctx?.drawImage(image, drawX - 15, drawZ - 15, 30, 30);
              } else {
                const offsetX = this.offsetX;
                const offsetZ = this.offsetZ;
                const pixDim = this.pixDim;
                image.onload = () => {
                  if (
                    this.offsetX === offsetX &&
                    offsetZ === this.offsetZ &&
                    pixDim === this.pixDim
                  ) {
                    this.ctx?.drawImage(image, drawX - 15, drawZ - 15, 30, 30);
                  }
                };
              }
              this.drawText(`(${structure[0]}, ${structure[1]})`, drawX, drawZ);
            }
          }
        }
      }
    }

    const server = db.servers.find((o) => o.seed === this.seed);
    if (this.showClaims && server !== undefined && server !== null) {
      const tmpStrokeStyle = this.ctx.strokeStyle;
      const tmpFillStyle = this.ctx.fillStyle;
      const tmpLineDash = this.ctx.getLineDash();

      for (const claim of db.servers[0].claims) {
        if (
          this.dimension !== claim.dimension &&
          !(
            this.dimension === -1 &&
            claim.dimension === 0 &&
            this.showOverworldClaimsInNether
          )
        ) {
          continue;
        }

        let name = claim.name;
        let colour = "white";

        if ((name === undefined || name === null) && claim.users) {
          for (let username of claim.users) {
            const userDetails = server.users.find(
              (o) => o.username.toLowerCase() === username.toLowerCase()
            );

            if (userDetails !== undefined && userDetails !== null) {
              name = userDetails.username;
              colour = userDetails.colour;
              break;
            } else {
              console.warn(
                `User ${username} not found on server ${server.name}`
              );
            }
          }
        }

        this.ctx.strokeStyle = colour;
        this.ctx.fillStyle = colour;

        this.ctx.setLineDash(claim.confirmed ? [] : [8, 8]);

        if (claim.points.length === 1) {
          const x = Math.floor(
            this.dimension === 0 ? claim.points[0].x : claim.points[0].x / 8
          );
          const z = Math.floor(
            this.dimension === 0 ? claim.points[0].z : claim.points[0].z / 8
          );

          const w = Math.floor(this.dimension === 0 ? 200 : 200 / 8);
          const h = Math.floor(this.dimension === 0 ? 200 : 200 / 8);

          const drawW = Math.floor(w / 4) * this.pixDim;
          const drawH = Math.floor(h / 4) * this.pixDim;

          const drawX =
            Math.floor(x / 4) * this.pixDim +
            this.offsetX * this.drawDim -
            Math.floor(drawW / 2);
          const drawY =
            Math.floor(z / 4) * this.pixDim +
            this.offsetZ * this.drawDim -
            Math.floor(drawH / 2);

          this.ctx.strokeRect(drawX, drawY, drawW, drawH);

          const textX = drawX + Math.floor(drawW / 2);
          const textY = drawY + Math.floor(drawH / 2);
          this.drawText(name ?? "", textX, textY, 0, 0);
        } else if (claim.points.length === 2) {
          const x1 = Math.floor(
            this.dimension === 0 ? claim.points[0].x : claim.points[0].x / 8
          );
          const y1 = Math.floor(
            this.dimension === 0 ? claim.points[0].z : claim.points[0].z / 8
          );
          const x2 = Math.floor(
            this.dimension === 0 ? claim.points[1].x : claim.points[1].x / 8
          );
          const y2 = Math.floor(
            this.dimension === 0 ? claim.points[1].z : claim.points[1].z / 8
          );

          const drawX1 =
            Math.floor(x1 / 4) * this.pixDim + this.offsetX * this.drawDim;
          const drawY1 =
            Math.floor(y1 / 4) * this.pixDim + this.offsetZ * this.drawDim;
          const drawX2 =
            Math.floor(x2 / 4) * this.pixDim + this.offsetX * this.drawDim;
          const drawY2 =
            Math.floor(y2 / 4) * this.pixDim + this.offsetZ * this.drawDim;

          const drawX = Math.min(drawX1, drawX2);
          const drawY = Math.min(drawY1, drawY2);

          const drawW = Math.abs(drawX1 - drawX2);
          const drawH = Math.abs(drawY1 - drawY2);

          //this.ctx.globalAlpha = 0.2;
          //this.ctx.fillRect(Math.min(x1, x2), Math.min(y1, y2), w, h);
          //this.ctx.globalAlpha = 1;

          this.ctx.strokeRect(drawX, drawY, drawW, drawH);

          const textX = drawX + Math.floor(drawW / 2);
          const textY = drawY + Math.floor(drawH / 2);
          this.drawText(name ?? "", textX, textY, 0, 0);
        } else if (claim.points.length > 2) {
          this.ctx.beginPath();

          for (let i = 0; i < claim.points.length; i++) {
            const drawX =
              Math.floor(claim.points[i].x / 4) * this.pixDim +
              this.offsetX * this.drawDim;
            const drawY =
              Math.floor(claim.points[i].z / 4) * this.pixDim +
              this.offsetZ * this.drawDim;
            if (i === 0) {
              this.ctx.moveTo(drawX, drawY);
            } else {
              this.ctx.lineTo(drawX, drawY);
              if (i === claim.points.length - 1) {
                const xOrigin =
                  Math.floor(claim.points[i].x / 4) * this.pixDim +
                  this.offsetX * this.drawDim;
                const yOrigin =
                  Math.floor(claim.points[i].z / 4) * this.pixDim +
                  this.offsetZ * this.drawDim;
                this.ctx.lineTo(xOrigin, yOrigin);
              }
            }
          }

          this.ctx.stroke();
        }
      }

      this.ctx.strokeStyle = tmpStrokeStyle;
      this.ctx.fillStyle = tmpFillStyle;
      this.ctx.setLineDash(tmpLineDash);
    }
  }

  _drawLoop(
    ctx: CanvasRenderingContext2D | null,
    colors: number[][],
    startX: number,
    startY: number,
    drawStartX: number,
    drawStartY: number,
    widthX: number,
    widthY: number
  ) {
    if (!ctx) return;

    startX = Math.floor(startX);
    startY = Math.floor(startY);
    const pixels = new Array(widthY * this.pixDim * widthX * this.pixDim);
    for (let jj = 0; jj < widthY * this.pixDim; jj++) {
      const realjj = Math.floor(jj / this.pixDim);
      const base = jj * widthX * this.pixDim;
      const jmodulo = jj % this.pixDim === 0;
      for (let ii = 0; ii < widthX * this.pixDim; ii++) {
        const realii = Math.floor(ii / this.pixDim);
        pixels[ii + base] = colors[realii * widthX + realjj];

        if (ii % this.pixDim === 0 && jmodulo) {
          if (!this.biomesDict[startX + realii]) {
            this.biomesDict[startX + realii] = {};
          }
        }
        this.biomesDict[startX + realii][startY + realjj] =
          colors[realii * widthX + realjj];
      }
    }
    const arr = Uint8ClampedArray.from(pixels.flat());
    const imageData = new ImageData(
      arr,
      widthX * this.pixDim,
      widthY * this.pixDim
    );
    ctx.putImageData(imageData, drawStartX, drawStartY);
  }

  getBiome(trueX: number, trueY: number) {
    if (this.biomesDict[trueX] && this.biomesDict[trueX][trueY]) {
      const rgba = this.biomesDict[trueX][trueY];
      const key = rgba.join("-");
      if (this.queue.COLORS) {
        const index = this.queue.COLORS.findIndex((x) => x.join("-") === key);
        if (index > -1) {
          return BIOMES.find((x) => x.value === index)?.label ?? null;
        }
      }
    }
    return null;
  }

  getBiomeAndPos(e: MouseEvent): [x: number, y: number, biome: string | null] {
    const rect = this.canvas.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    const trueX = Math.floor(
      x / this.pixDim - this.offsetX * (this.drawDim / this.pixDim)
    );
    const trueY = Math.floor(
      y / this.pixDim - this.offsetZ * (this.drawDim / this.pixDim)
    );
    const biome = this.getBiome(trueX, trueY);
    return [4 * trueX, 4 * trueY, biome];
  }

  spawnImage = "/img/spawn.png";

  eyeImage = "/img/eye.png";

  images: { [key: number]: string } = {
    /*  Desert_Pyramid */ 1: "/img/temple.png",
    /*  Jungle_Pyramid */ 2: "/img/jungle.png",
    /*  Swamp_Hut */ 3: "/img/hut.png",
    /*  Igloo */ 4: "/img/igloo.png",
    /*  Village */ 5: "/img/village.png",
    /*  Ocean_Ruin */ 6: "/img/ocean.png",
    /*  Shipwreck */ 7: "/img/wood.jpg",
    /*  Monument */ 8: "/img/guardian.png",
    /*  Mansion */ 9: "/img/mansion.png",
    /*  Outpost */ 10: "/img/outpost.png",
    /*  Ruined_Portal */ 11: "/img/portal.png",
    // 12 Ruined_Portal_N,
    /*  Ancient City */ 13: "/img/ancient_city.png",
    /*  Treasure */ 14: "/img/treasure.png",
    // 15 Mineshaft,
    /*  Fortress */ 16: "/img/fortress.png",
    /*  Bastion */ 17: "/img/bastion.png",
    /*  End_City */ 18: "/img/end_city.png",
    /*  End_Gateway */ 19: "/img/end_gateway.png",
  };
}
